import { Link } from "gatsby"
import React from "react"
import AppContext from "../context/app_context"
import Socials from "./socials"
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <div className="w-full mx-auto bg-white bg-opacity-97">
          <div className="flex flex-col items-center max-w-screen-lg px-4 py-5 mx-auto md:flex-row">
            <p className="py-2 text-xs text-neutral20">{t("rights")}</p>
            <div className="flex flex-row space-x-2">
            <Link to="/privacy" className="py-2 text-xs text-neutral20 md:pl-6">
              {t("privacyPolicy")}
            </Link>
            <Link to="/privacy-cookies" className="py-2 text-xs text-neutral20 md:pl-6">
              {t("cookiesPolicy")}
            </Link>
            </div>
            <Link to="/contact" className="py-2 text-xs text-neutral20 md:pl-6">
              {t("contact")}
            </Link>
            <div className="order-first pt-2 pb-8 md:ml-auto md:pt-0 md:pb-0 md:order-last">
              <Socials />
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
