import React from "react"
import { useTranslation } from "react-i18next"

export default function ButtonSwitcher(props) {
  const { t } = useTranslation()
  return (
    <div>
      <div className=" bg-neutral90 rounded-full p-1">
        <div className="relative flex items-center">
          <div className="absolute w-full">
            <div className="w-1/3 flex justify-between m-auto">
              <div className="h-3 w-px rounded-full opacity-0 transition-opacity duration-100 ease-in-out" />
              <div className="h-3 w-px rounded-full opacity-0 transition-opacity duration-100 ease-in-out" />
            </div>
          </div>

          <div
            className={`${
              props.tab === "organization"
                ? `translate-x-full`
                : `translate-x-0`
            } absolute left-0 inset-y-0 w-1/2 flex transition-all ease-in-out duration-200 transform bg-secondary60 text-white py-2 px-4 rounded-full text-xs uppercase`}
          />
          <button
            className={`${
              props.tab === "organization" ? `text-neutral60` : `text-white`
            } transition-colors focus:outline-none relative flex-1 flex font-semibold items-center justify-center cursor-pointer m-px p-px py-2.5 px-6 rounded-full text-xs uppercase`}
            onClick={props.onAdoptersClick}
            aria-label="Adopter button switch"
          >
            {t("buttonAdopter")}
          </button>
          <button
            className={`${
              props.tab === "organization" ? `text-white ` : `text-neutral60`
            } transition-colors focus:outline-none relative flex-1 flex font-semibold items-center justify-center cursor-pointer m-px p-px py-2.5 px-6 rounded-full text-xs uppercase`}
            onClick={props.onOrganizationClick}
            aria-label="Organization button switch"
          >
            {t("buttonOrganization")}
          </button>
        </div>
      </div>
    </div>
  )
}
