import { Link } from "gatsby"
import React from "react"
import BgSocial from "../images/svg/bg_social.svg"

export default function SocialButton(props) {
  return (
    <Link className="flex items-center justify-center transition ease-in-out transform hover:scale-110" to={props.url} target="_blank">
      <BgSocial className="relative" alt="" />
      <div className="absolute">
          {props.icon}
      </div>
    </Link>
  )
}
