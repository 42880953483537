import { Link } from "gatsby"
import React from "react"
import AppContext from "../context/app_context"
import Logo from "../images/svg/logo.svg"
import ButtonSwitcher from "./button_switcher"
import { animateScroll as scroll } from "react-scroll"

export default function Header(props) {

  const scrollToTop = () => scroll.scrollToTop({
    duration: 300,
  })

  return (
    <AppContext.Consumer>
      {state => (
        <div className="fixed z-10 w-full pb-4 mx-auto bg-white bg-opacity-97 sm:pb-0 sm:px-10">
          <div className="flex flex-col items-center justify-between max-w-screen-lg mx-auto sm:flex-row">
            <Link to="/">
              <Logo
                alt="Lappka logo"
                className="h-16"
              />
            </Link>
            {(props.showButtons ?? true) && (
              <ButtonSwitcher
                tab={state.isOrganizationView ? "organization" : "adopters"}
                onOrganizationClick={() => {
                  state.enableOrganizationView()
                  scrollToTop()
                }}
                onAdoptersClick={() => {
                  state.enableAdoptersView()
                  scrollToTop()
                }}
              />
            )}
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
