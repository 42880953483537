import React, { useState, useEffect } from "react"
import { Transition } from "@headlessui/react"
import CloseIcon from "../images/svg/close.svg"
import { useTranslation } from "react-i18next"
import Socials from "./socials"

export default function ThanksDialog(props) {
  const [noDisplay, setNoDisplay] = useState(!props.isOpen)

  useEffect(() => {
    if (!props.isOpen) setTimeout(() => setNoDisplay(true), 650)
    else setNoDisplay(false)
  }, [props.isOpen])

  let handleCloseClick = () => {
    props.onClose()
  }

  const { t } = useTranslation()

  return (
    <div
      className={`fixed z-20 inset-0 overflow-y-auto ${
        noDisplay ? "hidden" : ""
      }`}
    >
      <div className="flex flex-col justify-center h-screen">
        <Transition
          show={props.isOpen}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" aria-hidden="true">
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={handleCloseClick}
              onKeyDown={handleCloseClick}
              aria-label="Dismissable background"
              aria-hidden="true"
            />
          </div>

          <div className="flex flex-col items-center w-full">
            <div
              className={`inline-block w-11/12 lg:w-1/3 rounded-lg text-left overflow-hidden shadow-xl transform transition-all ${
                props.isOpen ? "opacity-100" : "opacity-0"
              }`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="flex flex-col px-12 py-6 bg-white">
                <div className="flex flex-col items-end">
                  <button
                    className="p-3 rounded-full hover:bg-neutral90 focus:outline-none"
                    onClick={handleCloseClick}
                  >
                    <CloseIcon alt="" />
                  </button>
                </div>

                <h2 className="text-2xl">
                  {t("Juz prawie ")}
                  <span className="font-bold">
                    {t("to masz!!")}
                  </span>
                </h2>
                <h3 className="pt-4 pb-6 text-neutral20">
                Zostało jeszcze kilka e-mailowych smaczków do zapisu. 
                Potwierdź adres e-mail, aby ostatecznie zapisać się do naszego newslettera.
                </h3>
                <Socials />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}