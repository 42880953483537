import React, { useState, useEffect } from "react"
import { Transition } from "@headlessui/react"
import CloseIcon from "../images/svg/close.svg"
import { useTranslation } from "react-i18next"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Link } from "gatsby"
import ProgressButton from "./button"

export default function FormDialog(props) {
  const [noDisplay, setNoDisplay] = useState(!props.isOpen)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!props.isOpen) setTimeout(() => setNoDisplay(true), 650)
    else setNoDisplay(false)
  }, [props.isOpen])

  let handleCloseClick = () => {
    props.onClose()
  }

  const handleSubmit = async (fields, resetForm) => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("homepage")
    const email = fields.email
    const message = fields.message

    const data = JSON.stringify({ email, message, token })
    const axiosOptions = {
      url: `${process.env.MESSAGE_URL}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoading(true)
    axios(axiosOptions)
      .then(response => {
        resetForm({ values: "" })
        setLoading(false)
        props.onClose()
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{
        acceptTerms: false,
        email: "",
        message: "",
      }}
      validationSchema={Yup.object().shape({
        acceptTerms: Yup.bool().oneOf([true]),
        email: Yup.string().email().required(),
        message: Yup.string().required(),
      })}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm)
      }}
    >
      {({ errors, touched }) => (
        <div
          className={`fixed z-20 inset-0 overflow-y-auto ${
            noDisplay ? "hidden" : ""
          }`}
        >
          <div className="flex flex-col justify-center h-screen">
            <Transition
              show={props.isOpen}
              enter="transition-opacity ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Form>
                <div className="fixed inset-0" aria-hidden="true">
                  <div
                    className="absolute inset-0 bg-gray-500 opacity-75"
                    onClick={handleCloseClick}
                    onKeyDown={handleCloseClick}
                    aria-label="Dismissable background"
                    aria-hidden="true"
                  />
                </div>

                <div className="flex flex-col items-center w-full">
                  <div
                    className={`inline-block w-11/12 lg:w-1/2 rounded-lg text-left overflow-hidden shadow-xl transform transition-all ${
                      props.isOpen ? "opacity-100" : "opacity-0"
                    }`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div className="flex flex-col px-12 pt-6 pb-12 bg-white">
                      <span className="flex flex-col items-end ">
                        <CloseIcon
                          className="p-3 rounded-full hover:bg-neutral90"
                          onClick={handleCloseClick}
                          onKeyDown={handleCloseClick}
                          aria-label="Close icon"
                          aria-hidden="true"
                          alt=""
                        />
                      </span>

                      <h2 className="text-2xl">
                        {t("contactTitleFirstPart")}
                        <span className="font-bold">
                          {t("contactTitleSecondPart")}
                        </span>
                      </h2>
                      <h3 className="pt-4 pb-6 text-neutral20">
                        {t("contactSubtitle")}
                      </h3>
                      <label
                        htmlFor="email"
                        className="block pb-1 text-sm font-medium text-neutral20"
                      >
                        {t("contactEmailLabel")}
                      </label>

                      <Field
                        type="email"
                        name="email"
                        placeholder={t("contactEmailHint")}
                        className={`${
                          errors.email && touched.email
                            ? "border-red-400"
                            : "border-neutral60"
                        } px-6 py-4 rounded-xl ring-0 focus:ring-0 text-primary40`}
                      />
                      <label
                        htmlFor="message"
                        className="block pt-6 pb-1 text-sm font-medium text-neutral20"
                      >
                        {t("contactMessageLabel")}
                      </label>
                      <Field
                        type="text"
                        name="message"
                        component="textarea"
                        placeholder={t("contactMessageHint")}
                        className={`${
                          errors.message && touched.message
                            ? "border-red-400"
                            : "border-neutral60"
                        } px-6 py-4 max-h-64 rounded-xl ring-0 focus:ring-0`}
                      />

                      <div className="pt-4 pl-4">
                        <label className="flex items-center">
                          <Field
                            type="checkbox"
                            name="acceptTerms"
                            className={`${
                              errors.acceptTerms && touched.acceptTerms
                                ? "border-red-400"
                                : "border-neutral60"
                            } rounded ring-0 focus:ring-0 text-primary40`}
                          />
                          <span
                            className={`${
                              errors.acceptTerms && touched.acceptTerms
                                ? "text-red-400"
                                : "text-neutral20"
                            } ml-2 text-xs `}
                          >
                            {t("termsFirstPart")}
                            <Link
                              className={`underline hover:text-primary40`}
                              to="/privacy"
                              target="_blank"
                            >
                              {t("termsSecondPart")}
                            </Link>
                          </span>
                        </label>
                      </div>

                      <div className="mt-8">
                        <ProgressButton loading={loading}>
                          <span className="block px-6 py-3 uppercase">
                            {t("buttonSendMessage")}
                          </span>
                        </ProgressButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Transition>
          </div>
        </div>
      )}
    </Formik>
  )
}
