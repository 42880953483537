import React from "react"
import FbLogo from "../images/svg/fb_logo.svg"
import InstagramLogo from "../images/svg/instagram_logo.svg"
import SocialButton from "./social_button"

export default function Socials(props) {
  return (
    <div className="flex space-x-4">
      <SocialButton icon={<FbLogo />} url="https://www.facebook.com/lappkapl" />
      <SocialButton icon={<InstagramLogo />} url="https://www.instagram.com/lappka.pl" />
    </div>
  )
}
