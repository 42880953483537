import React, { useEffect } from "react"
import Header from "../components/header"
import Footer from "./footer"
import FormDialog from "./form_dialog"
import firebase from "gatsby-plugin-firebase"
import AppContext from "../context/app_context"
import CookieConsent from "react-cookie-consent"
import ThanksDialog from "./thanks_dialog"
import ContactSuccessDialog from "./contact_success_dialog"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

export default function Layout(props) {
  useEffect(() => {
    firebase.analytics()
  }, [])
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <div className="mx-auto overflow-hidden">
          <CookieConsent
            location="bottom"
            buttonText="Akceptuj"
            enableDeclineButton="true"
            declineButtonText="Odrzuć"
            buttonClasses="px-6 py-3 mt-5 sm:mt-0 text-xs text-white rounded-full bg-primary40 hover:bg-primary20 focus:outline-none"
            buttonWrapperClasses="space-x-4 pt-4"
            declineButtonClasses="px-6 py-3 mt-5 sm:mt-0 text-xs text-neutral60 rounded-full bg-neutral90 hover:bg-neutral80 focus:outline-none"
            containerClasses="flex flex-col items-center rounded-lg shadow m-4 sm:m-6 sm:w-80 px-6 py-5 bg-white border-t-2 border-neutral90 fixed z-50"
            contentClasses="my-auto text-sm text-neutral20 mr-5"
            disableStyles={true}
            cookieName="gatsby-gdpr-google-analytics"
          >
            <h3 className="pb-1 text-lg">{t("cookiesTitle")}</h3>
            {t("cookiesDescription")}{" "}
            <Link
              className={`underline hover:text-primary40`}
              to="/privacy-cookies"
            >
             {t("cookiesPolictyDetails")}
            </Link>
            
          </CookieConsent>
          <FormDialog
            isOpen={state.isFormModalOpen}
            onClose={() => state.closeFormDialog()}
          />
          <ThanksDialog
            isOpen={state.isThanksModallOpen}
            onClose={() => state.closeThanksDialog()}
          />
          <ContactSuccessDialog
            isOpen={state.isContactSuccessModalOpen}
            onClose={() => state.closeContactSuccessDialog()}
          />
          <Header showButtons={props.showButtons} />
          <div className="flex flex-col min-h-screen">
            <div className="flex-grow">{props.children}</div>
            <Footer />
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
